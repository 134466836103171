<div class="container-fluid">
  <div class="row sub-container justify-content-end">
    <div class="card my-auto py-4">
      <h2 class="text-center login-title mb-md-3">Verify Email</h2>
      <span class="text-title mx-lg-5 mx-sm-2 my-2"
        >We have send you the validation code to
        {{ currentUserEmail }}
      </span>
      <form [formGroup]="otpForm">
        <div class="row">
          <div class="col-auto mx-auto my-2">
            <label for="OTP" class="otp-label">Enter OTP*</label>
            <ng-otp-input
              #ngOtpInput
              (onInputChange)="onOtpChange($event)"
              *ngIf="showOtpComponent"
              [config]="config"
              [ngClass]="showInvalidOtp ? 'otp-invalid' : 'otp-input-field'"
            ></ng-otp-input>
            <span *ngIf="showInvalidOtp">
              <img
                src="/assets/images/icons/invalid.svg"
                alt="invalid-icon"
                width=""
                height=""
              />
              <small [ngStyle]="{ color: '#DE1E62' }" class="mx-2"
                >Invalid OTP
              </small>
            </span>
            <br />
          </div>
        </div>
        <div class="row button-row">
          <div class="col-11 mx-auto">
            <div class="d-flex justify-content-evenly">
              <div class="col-5">
                <button
                  class="btn form-control cancel py-2"
                  routerLink="/login"
                >
                  Cancel
                </button>
              </div>
              <div class="col-5">
                <button
                  class="btn form-control next py-2"
                  (click)="submitOtp()"
                  [disabled]="disabledNextButton"
                >
                  Next
                  <span
                    *ngIf="showSpinner"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="row mt-2">
        <div class="d-flex justify-content-center">
          <button
            class="resend-otp"
            (click)="resendOtp()"
            [disabled]="disabledResendBtn"
          >
            Resend OTP? : {{ counter }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
