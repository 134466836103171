<div class="container-fluid">
  <div class="row sub-container justify-content-end">
    <div class="card my-auto py-5">
      <h2 class="text-center login-title mb-3">Login to your Account</h2>

      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="row my-2">
          <div class="col-11 mx-auto">
            <label for="email" class="email-label">Email*</label>
            <input
              (input)="onEmailChange()"
              type="email"
              id="email"
              class="form-control input-field py-2"
              formControlName="email"
              placeholder="Enter Email"
              [ngClass]="invalidEmail ? 'email-invalid' : ''"
              maxlength="30"
            />
            <span *ngIf="email.errors?.required && email.dirty">
              <img
                src="/assets/images/icons/invalid.svg"
                alt=""
                width=""
                height=""
              />
              <small [ngStyle]="{ color: '#DE1E62' }" class="mx-2"
                >Email is required.</small
              >
            </span>
            <span *ngIf="invalidEmail">
              <img
                src="/assets/images/icons/invalid.svg"
                alt="invalid-icon"
                width=""
                height=""
              />
              <small [ngStyle]="{ color: '#DE1E62' }" class="mx-2"
                >Invalid Email
              </small>
            </span>

            <span *ngIf="email.errors?.pattern">
              <img
                src="/assets/images/icons/invalid.svg"
                alt=""
                height=""
                width=""
              />
              <small [ngStyle]="{ color: '#DE1E62' }" class="mx-2"
                >Invalid Email</small
              >
            </span>
            <br />
          </div>
        </div>

        <div class="row my-2">
          <div class="col-11 mx-auto">
            <label for="password" class="password-label">Password*</label>
            <div class="d-flex">
              <input
                (input)="onPasswordChange()"
                id="password"
                #pwd
                type="password"
                class="form-control input-field py-2"
                formControlName="password"
                placeholder="Enter Password"
                maxlength="30"
                [ngClass]="invalidPassword ? 'password-invalid' : ''"
              />
              <span
                onkeypress=""
                class="eye-icon-span"
                (click)="pwd.type = pwd.type === 'text' ? 'password' : 'text'"
              >
                <img
                  src="assets/images/icons/eye off.svg"
                  alt=""
                  width=""
                  height=""
                  *ngIf="pwd.type == 'password'"
                />
                <img
                  src="assets/images/icons/eye on.svg"
                  alt=""
                  width=""
                  height=""
                  *ngIf="pwd.type == 'text'"
                />
              </span>
            </div>
            <span *ngIf="invalidPassword">
              <img
                src="/assets/images/icons/invalid.svg"
                alt="invalid-icon"
                width=""
                height=""
              />
              <small [ngStyle]="{ color: '#DE1E62' }" class="mx-2"
                >Invalid Password</small
              >
            </span>
            <span *ngIf="password.errors?.required && password.dirty">
              <img
                src="/assets/images/icons/invalid.svg"
                alt=""
                width=""
                height=""
              />
              <small [ngStyle]="{ color: '#DE1E62' }" class="mx-2"
                >Password is required.</small
              >
            </span>
            <span
              *ngIf="password.errors?.pattern && password.errors?.minlength"
            >
              <img
                src="/assets/images/icons/invalid.svg"
                alt=""
                width=""
                height=""
              />
              <small [ngStyle]="{ color: '#DE1E62' }" class="mx-2"
                >Invalid Password</small
              >
            </span>
            <br />
          </div>
        </div>

        <div class="row">
          <div class="col-11 mx-auto">
            <div class="d-flex justify-content-between">
              <div
                class="g-recaptcha"
                style="
                  transform: scale(0.9);
                  -webkit-transform: scale(0.7);
                  transform-origin: 0 0;
                  -webkit-transform-origin: 0 0;
                  width: 200px;
                "
              >
                <re-captcha formControlName="recaptcha"> </re-captcha>
              </div>
              <a class="forget mt-3" routerLink="/forgetpassword"
                >Forgot Password?</a
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-9 mx-auto">
            <button
              class="form-control py-2"
              [ngClass]="{
                'login-valid': loginForm.valid,
                'login-invalid': !loginForm.valid
              }"
            >
              Login
              <span
                *ngIf="showSpinner"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
