<div class="container-fluid">
  <div class="row sub-container justify-content-end">
    <div class="card my-auto py-4">
      <h2 class="text-center login-title mb-3">Forgot Password</h2>
      <span class="text-title mx-lg-5 mx-sm-2 my-2"
        >Please enter your registered email address to reset password</span
      >
      <form [formGroup]="forgetPasswordForm">
        <div class="row">
          <div class="col-11 mx-auto">
            <label for="email" class="email-label">Email*</label>
            <input
              type="email"
              id="email"
              (input)="onInputChange()"
              class="form-control input-field py-2"
              formControlName="email"
              placeholder="Enter Email"
              maxlength="30"
              [ngClass]="invalidEmail ? 'email-invalid' : ''"
            />
            <span *ngIf="invalidEmail">
              <img
                src="/assets/images/icons/invalid.svg"
                alt="invalid-icon"
                width=""
                height=""
              />
              <small [ngStyle]="{ color: '#DE1E62' }" class="mx-2"
                >Invalid Email
              </small>
            </span>
            <span *ngIf="email.errors?.required && email.dirty">
              <img
                src="/assets/images/icons/invalid.svg"
                alt=""
                height=""
                width=""
              />
              <small [ngStyle]="{ color: '#DE1E62' }" class="mx-2"
                >Email is required.</small
              >
            </span>
            <br />
          </div>
        </div>
        <div class="row w-100">
            <re-captcha formControlName="recaptcha" (resolved)="addTokenLog()" class="mx-lg-5 mx-md-4">
            </re-captcha>
        </div>
        <div class="row button-row">
          <div class="col-11 mx-auto">
            <div class="d-flex justify-content-evenly">
              <div class="col-5">
                <button
                  class="btn form-control cancel py-2"
                  routerLink="/login"
                >
                  Cancel
                </button>
              </div>
              <div class="col-5">
                <button
                  class="btn form-control next py-2"
                  (click)="submitEmail()"
                  [disabled]="forgetPasswordForm.invalid"
                >
                  Next
                  <span
                    *ngIf="showSpinner"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
