import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  mismatch: boolean = false;
  errorMessage: string = '';
  showSpinner: boolean = false;
  myForm = this.formbuilder.group({
    newPassword: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          '(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}'
        ),
      ],
    ],
    conPassword: ['', [Validators.required, Validators.minLength(8)]],
  });
  constructor(
    private formbuilder: FormBuilder,
    private authservice: AuthService,
    private router: Router,
    private toastrService: ToastrService
  ) {}

  onSubmit() {
    this.showSpinner = true;
    const newPassword = this.myForm.value.newPassword;
    const conPassword = this.myForm.value.conPassword;

    if (newPassword === conPassword) {
      this.authservice.resetPasswordData(this.myForm.value).subscribe(
        (result) => {
          this.showSpinner = false;
          this.toastrService.success('Password was reset Successfully');
          this.router.navigateByUrl('login');
        },
        (error) => {
          this.showSpinner = false;
          this.mismatch = true;
          this.errorMessage = 'Invalid Password';
        }
      );
    } else {
      this.showSpinner = false;
      this.mismatch = true;
      this.errorMessage = 'Password does not match.';
    }
  }

  onInputChange() {
    this.mismatch = false;
  }

  get newPassword() {
    return this.myForm.get('newpassword');
  }
  get conPassword() {
    return this.myForm.get('conPassword');
  }
}
