import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const contentType = 'application/json';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getActiveEvents(start_date: any, end_date: any): Observable<any> {
    const URL = 'admin/active-events';
    let params = new HttpParams();
    params = params.append('start_date_time', start_date);
    params = params.append('end_date_time', end_date);

    return this.http.get<any>(URL, {
      headers: new HttpHeaders({
        'Content-Type': contentType,
      }),
      params: params,
    });
  }
  getCurrentEvents(
    lat: number,
    long: number,
    distance_range: number,
    page: number,
    limit: number,
    search_query: any
  ): Observable<any> {
    const URL = 'admin/current-events';
    let params = new HttpParams();
    params = params.append('lat', lat);
    params = params.append('lon', long);
    params = params.append('distance_range', distance_range);
    params = params.append('page', page);
    params = params.append('limiit', limit);
    params = params.append('search_query', search_query);

    return this.http.get<any>(URL, {
      headers: new HttpHeaders({
        'Content-Type': contentType,
      }),
      params: params,
    });
  }

  getUpcomingEvents(
    lat: number,
    long: number,
    distance_range: number,
    page: number,
    limit: number,
    search_query: any
  ): Observable<any> {
    const URL = 'admin/upcoming-events';
    let params = new HttpParams();
    params = params.append('lat', lat);
    params = params.append('lon', long);
    params = params.append('distance_range', distance_range);
    params = params.append('page', page);
    params = params.append('limiit', limit);
    params = params.append('search_query', search_query);

    return this.http.get<any>(URL, {
      headers: new HttpHeaders({
        'Content-Type': contentType,
      }),
      params: params,
    });
  }
  getTotalAmounts(start_date: any, end_date: any): Observable<any> {
    const URL = 'admin/total-amount';
    let params = new HttpParams();
    params = params.append('start_date_time', start_date);
    params = params.append('end_date_time', end_date);

    return this.http.get<any>(URL, {
      headers: new HttpHeaders({
        'Content-Type': contentType,
      }),
      params: params,
    });
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  logoutUser() {
    const URL = 'admin/logout';

    return this.http.get<any>(URL, {
      headers: new HttpHeaders({
        'Content-Type': contentType,
      }),
    });
  }
}
