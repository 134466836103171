import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';
import { Utils } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  counter: number = 59;
  currentUserEmail: any;
  disabledResendBtn: boolean;
  constructor(
    private formbuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private utils: Utils,
    private toastr: ToastrService
  ) {}

  otpForm = this.formbuilder.group({});
  showInvalidOtp: boolean = false;
  currentUser: any;
  showSpinner: boolean = false;
  countOtpTimes: number = 0;
  disabledNextButton = false;

  ngOnInit(): void {
    this.getCurrentUserEmail();
    this.resendOtpCounter();
  }

  otp: string;
  showOtpComponent = true;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: true,
    placeholder: '',
    inputStyles: {
      height: '40px',
      width: '40px',
    },
  };

  getCurrentUserEmail() {
    this.currentUserEmail = localStorage.getItem('currentUserEmail');
  }

  onOtpChange(otp: any) {
    this.otp = otp;
    this.showInvalidOtp = false;
  }
  submitOtp() {
    this.countOtpTimes++;
    console.log(this.countOtpTimes);
    if (this.countOtpTimes <= 5) {
      this.showSpinner = true;

      this.authService.verifyEmail(this.otp).subscribe(
        (result) => {
          console.log(result);
          if (result) {
            this.showSpinner = false;
            this.toastr.success('OTP  verified successfully.');
            this.router.navigateByUrl('resetpassword');
          }
        },
        (error) => {
          this.showSpinner = false;
          this.showInvalidOtp = true;
        }
      );
    } else {
      this.disabledResendBtn = true;
      this.toastr.error(
        'You have entered wrong OTP for 5 times, please wait for two hours.'
      );
      // this.onConfigChange();
      this.disabledNextButton = true;
      this.counter = 1;
    }
  }
  resendOtpCounter() {
    const interval = setInterval(() => {
      this.disabledResendBtn = true;
      this.counter--;
      if (this.counter == 0) {
        if (this.countOtpTimes <= 5) {
          clearInterval(interval);
          this.disabledResendBtn = false;
        } else clearInterval(interval);
        this.disabledResendBtn = true;
      }
    }, 1000);
  }

  resendOtp() {
    console.log(this.disabledResendBtn);

    this.countOtpTimes++;
    console.log(this.countOtpTimes);
    this.counter = 59;
    this.resendOtpCounter();
    this.showInvalidOtp = false;
    this.setVal(null);
    const currentUserEmail = localStorage.getItem('currentUserEmail');
    if (this.countOtpTimes <= 5) {
      this.authService.forgetPasswordData(currentUserEmail).subscribe(
        (result) => {
          if (result) {
            this.toastr.success(
              'OTP resend successfully to your registered email address.'
            );
          } else {
            this.showErrorMessage();
            this.showSpinner = false;
          }
        },
        (error) => {
          this.showSpinner = false;
        }
      );
    } else {
      this.disabledResendBtn = true;
      this.toastr.error(
        'You have entered wrong OTP for 5 times, please wait for two hours.'
      );
      // this.onConfigChange();
      this.disabledNextButton = true;
      this.counter = 0;
    }
  }

  showErrorMessage() {
    const errorMessage = localStorage.getItem('errorMessage');
    const errorcode = localStorage.getItem('errorcode');
    console.log(errorMessage && errorcode);
    if (errorMessage === 'Invalid OTP.') {
      this.showInvalidOtp = true;
    }
  }

  setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  toggleDisable() {
    if (this.ngOtpInput.otpForm) {
      if (this.ngOtpInput.otpForm.disabled) {
        this.ngOtpInput.otpForm.enable();
      } else {
        this.ngOtpInput.otpForm.disable();
      }
    }
  }

  onConfigChange() {
    this.showOtpComponent = false;
    this.otp = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 20000);
  }
}
