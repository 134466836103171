import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ToastrModule } from 'ngx-toastr';
import { SafePipe } from './pipes/safe.pipe';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxMaskModule } from 'ngx-mask';
import { ClipboardModule } from 'ngx-clipboard';
import { ToastComponent } from './components/toast/toast.component';
import { AngularResizedEventModule } from 'angular-resize-event';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    // Daterangepicker,
    NgSelectModule,
    NgxUiLoaderModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true,
    }), // ToastrModule added
    NgxMaskModule.forRoot({
      validation: true,
    }),
    ClipboardModule,
    AngularResizedEventModule,
  ],
  declarations: [SafePipe, ClickOutsideDirective, ToastComponent],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    // Daterangepicker,
    NgSelectModule,
    SafePipe,
    ClickOutsideDirective,
    NgxMaskModule,
    NgxUiLoaderModule,
    ClipboardModule,
    ToastComponent,
    // LoaderComponent
  ],
  providers: [],
})
export class SharedModule {}
