import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { tap } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../services/utils.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authservice: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private utils: Utils,
    private toastrService: ToastrService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((evt) => {
        const currentUser = this.utils.getItem('currentUser');
        if (currentUser && evt instanceof HttpResponse) {
          return;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
          console.log(error);
        } else {
          // server-side error
          errorMessage = `Error error: ${error.status}\nMessage: ${error.message}`;
          localStorage.setItem('errorMessage', error.error.message);
          localStorage.setItem('errorcode', error.error.code);
          console.log(error);
        }

        if (error.status === 403) {
          this.router.navigate(['/forbidden-access']);
        }
         else if (error.status === 404) {
          this.router.navigate(['/404']);
        }
        else  if (error.status === 500) {
          this.toastrService.error('Internal server error');
          this.router.navigate(['/server-error']);
        }
        else if (error.status === 401) {
          this.router.navigateByUrl('login');
        }
        return throwError(errorMessage);
      }) as any
    );
  }
}
