import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { Utils } from '../services/utils.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private utils: Utils) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available

    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      request = request.clone({
        setHeaders: {
          'rest-api-key': '4d717e90-7323-423c-b198-47eabe4975a5',
          Authorization: `Bearer ${access_token}`,
        },
      });
    }

    return next.handle(request);
  }
}
