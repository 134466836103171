import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Utils {
  public appName = environment.appName;

  // Other Variables
  // ---------------------------------------------------

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private location: Location
  ) {}

  // Misc
  // --------------------------------------------------

  // LocalStorage function
  // ---------------------------------------------------
  setItem(key, value) {
    localStorage.setItem(`${this.appName}.` + key, JSON.stringify(value));
  }

  getItem(key) {
    const data = localStorage.getItem(`${this.appName}.` + key);
    if (data) {
      return JSON.parse(localStorage.getItem(`${this.appName}.` + key));
    } else {
      return null;
    }
  }

  removeItem(key) {
    localStorage.removeItem(`${this.appName}.` + key);
  }

  clearLocalStorage() {
    const arr = [];
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substring(0, 7) == this.appName) {
        arr.push(localStorage.key(i));
      }
    }

    for (let i = 0; i < arr.length; i++) {
      localStorage.removeItem(arr[i]);
    }
  }

  // Error display function
  // ---------------------------------------------------
  showAppError(res) {
    // skip messages for invalid access token
    // as we are handling it in error interceptor
    // by silently loggin user in case of token expire or invalid

    if (!res.IsSuccess && res.ErrorCode === 1000) {
      return false;
    }

    if (res.ArgumentErrors && res.ArgumentErrors.length) {
      this.toastr.error(res.ArgumentErrors[0].ErrorMessage);
    } else {
      this.toastr.error(res.ErrorMessage);
    }
  }

  // make base64 image form file object
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  // Returns a function, that, when invoked, will only be triggered at most once
  // during a given window of time. Normally, the throttled function will run
  // as much as it can, without ever going more than once per `wait` duration;
  // but if you'd like to disable the execution on the leading edge, pass
  // `{leading: false}`. To disable execution on the trailing edge, ditto.
  // throttle2(func, wait, options) {
  //   let context;
  //   let args;
  //   let result;
  //   let timeout = null;
  //   let previous = 0;
  //   if (!options) {
  //     options = {};
  //   }
  //   const later = () => {
  //     previous = options.leading === false ? 0 : Date.now();
  //     timeout = null;
  //     result = func.apply(context, args);
  //     if (!timeout) {
  //       context = args = null;
  //     }
  //   };
  //   return function () {
  //     const now = Date.now();
  //     if (!previous && options.leading === false) {
  //       previous = now;
  //     }
  //     const remaining = wait - (now - previous);
  //     context = this;
  //     args = arguments;
  //     if (remaining <= 0 || remaining > wait) {
  //       if (timeout) {
  //         clearTimeout(timeout);
  //         timeout = null;
  //       }
  //       previous = now;
  //       result = func.apply(context, args);
  //       if (!timeout) {
  //         context = args = null;
  //       }
  //     } else if (!timeout && options.trailing !== false) {
  //       timeout = setTimeout(later, remaining);
  //     }
  //     return result;
  //   };
  // }

  throttle(callback, limit) {
    let waiting = false; // Initially, we're not waiting
    return function () {
      // We return a throttled function
      if (!waiting) {
        // If we're not waiting
        callback.apply(this, arguments); // Execute users function
        waiting = true; // Prevent future invocations
        setTimeout(function () {
          // After a period of time
          waiting = false; // And allow future invocations
        }, limit);
      }
    };
  }

  // timeout
  // timeout(fn, wait) {
  //   wait = wait || 0;
  //   const timeout = setTimeout(fn, wait);
  //   return timeout;
  // }

  /*  =Files Methods
  -------------------------------------------------------------- */

  // Get file extension
  // ex: getFileExtension("username.png") returns png
  // ---------------------------------------------------
  getFileExtension(fileName) {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(fileName);
  }

  // Get file name from path
  // ex: getFileNameFromPath("Users/Subhan/Downloads/Untitled_ Nov 18 2020 5_06 PM.webm")
  // returns Untitled_ Nov 18 2020 5_06 PM.webm
  getFileNameFromPath(path) {
    return path.replace(/^.*[\\\/]/, '');
  }

  // Misc
  // ---------------------------------------------------
  getFullYear() {
    return new Date().getFullYear();
  }

  // Events
  // ---------------------------------------------------
  dispatchResizeOnWindow() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200);
  }

  // Get values from Object
  // ---------------------------------------------------
  getValues(obj) {
    return Object.keys(obj).map((k) => obj[k]);
  }

  // Move item in array to front, if exist item will be remove
  // ---------------------------------------------------
  moveItemToFront(item: any, array: any) {
    // let data = ["email","role","type","name"];
    array = array.filter((i) => i !== item);
    array.unshift(item);
    return array;
  }

  goToBack() {
    this.location.back();
  }

  // check local AppVersion
  // ----------------------------
  isAppVersionSame() {
    if (!environment.appVersion) {
      return false;
    }

    return environment.appVersion === this.getItem('appVersion');
  }
}
