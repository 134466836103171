import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';
import { Utils } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  invalidEmail: boolean = false;
  invalidPassword: boolean = false;

  showSpinner: boolean = false;

  loginForm = this.formbuilder.group({
    recaptcha: new FormControl(null, Validators.required),
    email: [
      '',
      [Validators.required, Validators.email, Validators.maxLength(30)],
    ],
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30),
        Validators.pattern(
          '(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}'
        ),
      ],
    ],

  });


  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private utils: Utils,
    private toastrService: ToastrService
  ) {}

  onSubmit() {
    if (this.loginForm.valid) {
      this.showSpinner = true;
      this.authService.sendLoginData(this.loginForm.value).subscribe(
        (result) => {
          if (result) {
            this.utils.clearLocalStorage();
            const access_token = result.data.tokens.access.token;
            this.utils.setItem('currentUser', result.data.user);
            localStorage.setItem('access_token', access_token);
            this.toastrService.success('Login Successful');
            this.router.navigateByUrl('dashboard');
          }
        },
        (error) => {
          this.showSpinner = false;
          this.showErrorMessage();
        }
      );
    }
  }
  showErrorMessage() {
    const errorMessage = localStorage.getItem('errorMessage');
    const errorcode = localStorage.getItem('errorcode');
    console.log(errorMessage);
    if (errorMessage === 'Password is wrong.') {
      this.invalidPassword = true;
    } else if (errorcode === '400') {
      this.invalidEmail = true;
    } else if (errorcode === '404') {
      this.toastrService.warning(errorMessage);
    } else {
      this.toastrService.error(errorMessage);
    }
  }

  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }
  onEmailChange() {
    this.invalidEmail = false;
  }
  onPasswordChange() {
    this.invalidPassword = false;
  }
}
