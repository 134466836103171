<div class="container-fluid">
  <div class="row main-container">
    <div class="col-md-6 p-0">
      <router-outlet></router-outlet>
    </div>
    <div class="col-md-6 p-0">
      <div
        class="image-container d-flex align-items-center justify-content-center"
      >
        <img
          src="/assets/images/common/thatsmyjamlogo.svg"
          alt="tdm logo"
          class="d-none d-md-block"
          height=""
          width=""
        />
      </div>
    </div>
  </div>
  <div class="footer fixed-bottom d-flex justify-content-center">
    <p class="footer-title my-auto">
      © {{ currentYear }} All Rights Reserved to That’s My Jam General
      Contracting, Inc
    </p>
  </div>
</div>
