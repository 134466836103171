<div class="container-fluid">

  <div class="row justify-content-center"
   style="border-bottom: 1px solid rgba(255, 255, 255, 0.2)">
    <div class="col-lg-10 d-flex justify-content-between">
      <div class="align-self-center pt-1">
        <img
          src="/assets/images/common/small_logo.svg"
          alt="logo-icon"
          class="logo-image"
          width=""
          height=""
        />
      </div>
      <div class="d-flex align-self-center">
        <h3 class="user-title pt-2 mx-3">
          Welcome {{ CurrentUser.full_name }}
        </h3>
        <img
          src="/assets/images/icons/log out.svg"
          alt=""
          (click)="openMd(logoutModal)"
          onkeypress=""
          class="logout-icon"
          width=""
          height=""
        />
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-lg-10">
      <div class="d-flex flex-wrap">
        <h2 class="dashboard-title me-auto my-auto">
          Dashboard
          <span class="mx-3 refresh" (click)="onSelectedDates()" onkeypress=""
            ><img
              src="/assets/images/icons/refresh left.svg"
              alt=""
              width=""
              height=""
          /></span>
        </h2>

        <div class="d-flex flex-column mb-3 mx-4">
          <label for="Start Date" class="startdate py-2">Start Date</label>
          <div class="d-flex">
            <input
              (click)="d1.toggle()"
              onkeypress=""
              (dateSelect)="onStartDateSelect()"
              class="form-control datepicker"
              placeholder="Select Date"
              name="dp"
              [(ngModel)]="startDate"
              ngbDatepicker
              [placement]="'bottom-right'"
              restoreFocus="true"
              [displayMonths]="1"
              [dayTemplate]="customDay"
              navigation="arrows"
              outsideDays="false"
              [showWeekNumbers]="false"
              [footerTemplate]="footerTemplate"
              #d1="ngbDatepicker"
              readonly
            />
            <span class="my-auto">
              <img
                src="/assets/images/icons/datepicker.svg"
                alt=""
                (click)="d1.toggle()"
                onkeypress=""
                style="margin-left: -20px; cursor: pointer"
                width=""
                height=""
              />
            </span>
            <ng-template #footerTemplate>
              <hr class="my-0" />
              <h4 class="text-center today-date-footer">
                {{ todayDate | date: "EEEE, MMMM d, y" }}
              </h4>
            </ng-template>

            <ng-template
              #customDay
              let-date
              let-currentMonth="currentMonth"
              let-selected="selected"
              let-disabled="disabled"
              let-focused="focused"
            >
              <span
                class="custom-day"
                [class.focused]="focused"
                [class.hidden]="date.month !== currentMonth"
                [class.text-muted]="disabled"
              >
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>
        <div class="d-flex flex-column mb-3">
          <label for="Start Date" class="startdate py-2">End Date</label>
          <div class="d-flex">
            <input
              (click)="d2.toggle()"
              onkeypress=""
              [placement]="'bottom-left'"
              class="form-control datepicker"
              (dateSelect)="onEndDateSelect()"
              placeholder="End Date"
              name="dp"
              [(ngModel)]="endDate"
              ngbDatepicker
              [displayMonths]="1"
              [dayTemplate]="customDay"
              navigation="arrows"
              outsideDays="false"
              [markDisabled]="isDisabled"
              [showWeekNumbers]="false"
              [footerTemplate]="footerTemplate"
              #d2="ngbDatepicker"
              readonly
            />

            <span class="my-auto">
              <img
                src="/assets/images/icons/datepicker.svg"
                (click)="d2.toggle()"
                onkeypress=""
                alt="datepicker select icon"
                style="margin-left: -20px; cursor: pointer"
                width=""
                height=""
              />
            </span>
            <ng-template #footerTemplate>
              <hr class="my-0" />
              <h4 class="text-center today-date-footer">
                {{ todayDate | date: "EEEE, MMMM d, y" }}
              </h4>
            </ng-template>

            <ng-template
              #customDay
              let-date
              let-currentMonth="currentMonth"
              let-selected="selected"
              let-disabled="disabled"
              let-focused="focused"
            >
              <span
                class="custom-day"
                [class.focused]="focused"
                [class.hidden]="date.month !== currentMonth || isDisabled"
                [class.text-muted]="disabled"
              >
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>

        <img
          src="/assets/images/icons/check.svg"
          alt="check-icon"
          class="mx-4 mt-3 check"
          (click)="onSelectedDates()"
          onkeypress=""
          width=""
          height=""
        />

        <img
          src="/assets/images/icons/rotation left.svg"
          alt="rotation-left-icon"
          class="mt-3 rotation-left"
          (click)="resetSelectedDates()"
          onkeypress=""
          width=""
          height=""
        />
      </div>
    </div>
  </div>
  <div class="row justify-content-center mb-3">
    <div class="col-lg-10">
      <div class="d-flex justify-content-between flex-wrap">
        <div class="card d-flex flex-column justify-content-center">
          <div class="d-flex justify-content-evenly">
            <div
              class="image-container d-flex justify-content-center align-items-center"
            >
              <img
                src="/assets/images/common/Event (1).svg"
                alt=""
                width=""
                height=""
              />
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <h3 class="title">{{ totalActiveEvents }}</h3>
            </div>
          </div>
          <p class="gradient-text align-self-center mt-4">
            Total No of Active Events
          </p>
        </div>

        <div class="card d-flex flex-column justify-content-center">
          <div class="d-flex justify-content-evenly">
            <div
              class="image-container d-flex justify-content-center align-items-center"
            >
              <img
                src="/assets/images/common/Cash.svg"
                alt="cash-icon"
                width=""
                height=""
              />
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <h3 class="title">$ {{ totalEarnings }}</h3>
            </div>
          </div>

          <p class="gradient-text align-self-center mt-4">Total Earnings</p>
        </div>
        <div class="card d-flex flex-column justify-content-center">
          <div class="d-flex justify-content-evenly">
            <div
              class="image-container d-flex justify-content-center align-items-center"
            >
              <img
                src="/assets/images/common/Tip.svg"
                alt="tip-icon"
                width=""
                height=""
              />
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <h3 class="title">$ {{ totalBidAmount }}</h3>
            </div>
          </div>
          <p class="gradient-text align-self-center mt-4">Total Bid Amounts</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center pt-3">
    <div class="col-lg-10">
      <div class="d-flex">
        <div class="justify-content-lg-between">
          <a
            class="links"
            (click)="currentEvents()"
            [ngClass]="linkActive ? 'active' : ''"
            >Current Events</a
          >
          <a
            class="links"
            (click)="upcomingEvents()"
            [ngClass]="!linkActive ? 'active' : ''"
            >Upcoming Events</a
          >
        </div>
        <div class="mx-4">
          <img
            class="filter"
            src="/assets/images/icons/filter.svg"
            alt="filter-icon"
            (click)="openMd(content)"
            onkeypress=""
            width=""
            height=""
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center py-2">
    <div class="col-lg-10">
      <div>
        <img
          src="/assets/images/icons/Search.svg"
          style="margin-bottom: -60px; margin-left: 10px "
          alt="search-icon"
          width=""
          height=""
        />
        <label for="search"></label>
        <input
          #searchbox
          (input)="search(searchbox.value)"
          id="search"
          onkeypress=""
          type="search"
          class="form-control search"
          placeholder="Search"
        />
      </div>
    </div>
  </div>
  <div class="row justify-content-center py-3">
    <div class="col-lg-10">
      <div class="row justify-content-lg-between flex-wrap">
        <div *ngFor="let event of events" class="small-card mb-3 d-flex">
          <div class="mx-2 " style="margin-top:20px">
            <img
              src="{{ event.picture }}"
              class="event-image"
              alt="event-image"
              width=""
              height=""
            />
          </div>
          <div style="background: transparent" class="mt-4">
            <p class="text-event mx-1">{{ event.title }}</p>
            <p class="event-date mx-1">
              {{ event.start_date_time | date: "EEE, MMM d, y" }} -
              {{ event.end_date_time | date: "EEE, MMM d, y" }} ,
              {{ event.start_date_time | date: "shortTime" }}-{{
                event.end_date_time | date: "shortTime"
              }}
            </p>
            <div class="d-flex event-location">
              <img
                src="/assets/images/icons/icon-location.svg"
                class="mx-1"
                alt="event-location-icon"
                width="12"
                height="12"
              />
               {{ event.event_location }}
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
      bdColor="rgba(51,51,51,0.6)"
      size="medium"
      color="purple"
      type="ball-scale-multiple"
    >
    </ngx-spinner>

<ng-template #content let-modal>
  <div class="modal-body">
    <h3 class="modal-title text-center mt-2">
      Set Distance : {{ rangevalue + "  mi" }}
    </h3>
    <div class="row justify-content-center">
      <div class="col-10">
        <form class="my-3">
          <label for="range"></label>
          <input
            type="range"
            id="range"
            [value]="rangevalue"
            name="distance"
            min="1"
            max="50"
            step="1"
            class="w-100 slider"
            (input)="valuechanged($event)"
          />
        </form>
      </div>
    </div>

    <div class="row d-flex justify-content-evenly mt-4">
      <div class="col-4">
        <button
          class="btn form-control cancel py-2"
          (click)="modal.close('Close click')"
        >
          Cancel
        </button>
      </div>
      <div class="col-4">
        <button class="btn form-control next py-2" (click)="onRangeChange()">
          Next
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #logoutModal let-modal>
  <div class="modal-body">
    <h3 class="modal-title text-center mt-4">
      Are you sure you want to logout?
    </h3>
    <div class="row d-flex justify-content-evenly mt-4">
      <div class="col-4">
        <button
          class="btn form-control cancel py-2"
          (click)="modal.close('Close click')"
        >
          Cancel
        </button>
      </div>
      <div class="col-4">
        <button class="btn form-control next py-2" (click)="logout()">
          Logout
        </button>
      </div>
    </div>
  </div>
</ng-template>
