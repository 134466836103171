import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Utils } from '../../core/services/utils.service';

@Injectable()
export class HttpApiInterceptor implements HttpInterceptor {
  public baseUrl = environment.baseUrl;

  constructor(private utils: Utils) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const apiKey = this.utils.getItem('apiKey');
    const reqHeaders = {
      // 'Content-Type': 'application/json'
    };

    if (apiKey) {
      reqHeaders['api-key'] = apiKey;
    }

    request = request.clone({
      url: this.baseUrl + request.url,
      setHeaders: reqHeaders,
    });

    return next.handle(request);
  }
}
