import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';
import { Utils } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent {
  invalidEmail: boolean = false;
  showSpinner: boolean = false;
  constructor(
    private formbuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastrService: ToastrService,
    private utils: Utils
  ) {}

  forgetPasswordForm = this.formbuilder.group({
    recaptcha: new FormControl(null, Validators.required),

    email: ['', [Validators.required, Validators.email]],
  });

  get email() {
    return this.forgetPasswordForm.get('email');
  }
  onInputChange() {
    this.invalidEmail = false;
  }
  public addTokenLog() {
    console.log('recaptcha working');
  }
  submitEmail() {
    this.showSpinner = true;
    localStorage.setItem(
      'currentUserEmail',
      this.forgetPasswordForm.value.email
    );

    this.authService
      .forgetPasswordData(this.forgetPasswordForm.value.email)
      .subscribe(
        (result) => {
          const access_token = result.data.tokens.access.token;
          localStorage.setItem('access_token', access_token);
          this.showSpinner = false;
          this.toastrService.success('OTP sent successfully.');
          this.router.navigateByUrl('verifyemail');
        },
        (error) => {
          this.showSpinner = false;
          this.invalidEmail = true;
        }
      );
  }
}
