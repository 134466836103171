import { Component, OnInit } from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { Utils } from 'src/app/core/services/utils.service';
import {
  CustomAdapter,
  CustomDateParserFormatter,
} from 'src/app/shared/components/date-picker/date-picker';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],

  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class DashboardComponent implements OnInit {
  startDate: string;
  endDate: any;
  mindate: any;
  rangevalue: number = 50;
  placement = 'bottom';
  todayDate: any;
  searchterm: string = '';
  page: number = 1;
  limit: number = 20;
  latitude: any;
  longitude: any;
  totalActiveEvents: number = 0;
  linkActive: boolean = true;
  totalEarnings: number = 0;
  totalBidAmount: number = 0;
  private searchTerms = new Subject<string>();
  constructor(
    private utils: Utils,
    private modalService: NgbModal,
    private ngbCalendar: NgbCalendar,
    private dateformatter: NgbDateParserFormatter,
    private dashboardservice: DashboardService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  events = [];
  isDisabled: any;

  ngOnInit(): void {
    this.todayDate = this.dateformatter.format(this.today);
    this.getCurrentLocation();
    this.mindate = this.today;
    this.startDate = this.dateformatter.format(this.today);
    this.endDate = this.dateformatter.format(this.initialEndDate);
    console.log(this.endDate);
    this.activeEvents();
    this.totalAmount();

    this.searchTerms
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((term) => this.setTerm(term));
  }

  valuechanged(event: any) {
    this.rangevalue = event.target.value;
  }

  onRangeChange() {
    this.modalService.dismissAll();

    if( this.linkActive){

      this.currentEvents();
    }
    else
        this.upcomingEvents();
  }

  onStartDateSelect() {
    this.endDate = this.startDate;
    const disabledDate = this.dateformatter.parse(this.endDate);
    this.isDisabled = (date: NgbDate, current: { month: 2 }) =>
      date.day < disabledDate.day &&
      date.month == disabledDate.month &&
      date.year == disabledDate.year;
  }
  onEndDateSelect() {
    console.log(this.endDate);
  }

  activeEvents() {

    this.spinner.show();
    this.dashboardservice
      .getActiveEvents(this.startDate, this.endDate)
      .subscribe((result) => {
        this.totalActiveEvents = result.data.count;
        this.spinner.hide();
      });
  }
  currentEvents() {
    this.events=[];
    this.spinner.show();
    this.linkActive = true;
    const distance_range = this.rangevalue;
    const search_query = this.searchterm;
    this.dashboardservice
      .getCurrentEvents(
        this.latitude,
        this.longitude,
        distance_range,
        this.page,
        this.limit,
        search_query
      )
      .subscribe((result) => {
        this.events = result.data.rows;
        this.spinner.hide();
      });
  }
  upcomingEvents() {
    this.events=[];
    this.spinner.show();
    this.linkActive = false;
    const distance_range = this.rangevalue;
    const search_query = this.searchterm;
    this.dashboardservice
      .getUpcomingEvents(
        this.latitude,
        this.longitude,
        distance_range,
        this.page,
        this.limit,
        search_query
      )
      .subscribe((result) => {
        this.events = result.data.rows;
        this.spinner.hide();
      });
  }
  totalAmount() {
    this.dashboardservice
      .getTotalAmounts(this.startDate, this.endDate)
      .subscribe((result) => {
        this.totalBidAmount = result.data[0]?.total_bid_amount
          ? result.data[0]?.total_bid_amount
          : 0;
        this.totalEarnings = result.data[0]?.total_earning
          ? result.data[0]?.total_earning
          : 0;
      });
  }

  search(term: string) {
    this.searchTerms.next(term);
  }
  setTerm(term: any) {
    this.searchterm = term;
    if (this.linkActive) {
      this.currentEvents();
    } else {
      this.upcomingEvents();
    }
  }

  onSelectedDates() {
    this.activeEvents();
    this.totalAmount();
  }
  resetSelectedDates() {
    this.isDisabled = false;
    this.startDate = this.dateformatter.format(this.today);
    this.endDate = this.dateformatter.format(this.initialEndDate);
    this.spinner.show();
    this.activeEvents();
    this.totalAmount();
  }
  get today() {
    return this.ngbCalendar.getToday();
  }
  get initialEndDate() {
    return this.ngbCalendar.getNext(this.today, 'd', 7);
  }
  get CurrentUser() {
    return this.utils.getItem('currentUser');
  }

  openMd(content: any) {
    this.modalService.open(content, { size: 'md', centered: true });
  }
  getCurrentLocation() {
    this.dashboardservice.getPosition().then((pos) => {
      this.latitude = pos.lat;
      this.longitude = pos.lng;
      this.currentEvents();
    });
  }
  logout() {
    const errorMessage = localStorage.getItem('errorMessage');
    this.modalService.dismissAll();
    this.dashboardservice.logoutUser().subscribe((res) => {
      if (res) {
        localStorage.clear();
        this.toastr.success('User logged out successfully.');
        this.router.navigate(['login']);
      } else {
        this.toastr.error(errorMessage);
      }
    });
  }
  ngOnDestroy() {
    this.modalService.dismissAll();
  }
}
