<div class="container-fluid">
  <div class="row sub-container justify-content-end">
    <div class="card my-auto py-2">
      <h2 class="text-center login-title mt-3">Reset Password</h2>
      <span class="text-title my-1 mx-lg-3 mx-sm-2"
        >Password must include atleast 8 characters including 1 number or 1
        special character</span
      >
      <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <div class="row my-3">
          <div class="col-11 mx-auto">
            <label for="newpassword" class="email-label pb-1"
              >New Password*</label
            >
            <input
              (input)="onInputChange()"
              #pwd
              type="password"
              id="newpassword"
              class="form-control input-field py-2"
              formControlName="newPassword"
              placeholder="Enter Password"
              maxlength="30"
            />
          </div>
        </div>
        <div class="row my-3">
          <div class="col-11 mx-auto">
            <label for="confirmpassword" class="email-label pb-1"
              >Confirm Password*</label
            >
            <div class="d-flex">
              <input
                (input)="onInputChange()"
                #conpwd
                id="confirmpassword"
                type="password"
                class="form-control input-field py-2"
                formControlName="conPassword"
                placeholder="Confirm Password"
                [ngClass]="mismatch ? 'mismatch' : ''"
                maxlength="30"
              />
              <span
                onkeypress=""
                class="eye-icon-span"
                (click)="
                  conpwd.type = conpwd.type === 'text' ? 'password' : 'text'
                "
                tabindex="-1"
              >
                <img
                  src="assets/images/icons/eye off.svg"
                  alt=""
                  width=""
                  height=""
                  *ngIf="conpwd.type == 'password'"
                />
                <img
                  src="assets/images/icons/eye on.svg"
                  alt=""
                  width=""
                  height=""
                  *ngIf="conpwd.type == 'text'"
                />
              </span>
            </div>
            <small *ngIf="mismatch">
              <img
                src="/assets/images/icons/invalid.svg"
                alt=""
                width=""
                height=""
              />
              <small
                [ngStyle]="{ color: '#DE1E62' }"
                class="pwdvalidation mx-2"
                >{{ errorMessage }}</small
              >
            </small>
            <br />
          </div>
        </div>
        <div class="row button-row">
          <div class="col-11 mx-auto mt-3">
            <div class="d-flex justify-content-evenly">
              <div class="col-5">
                <button
                  class="btn form-control cancel py-2"
                  routerLink="/login"
                >
                  Cancel
                </button>
              </div>
              <div class="col-5">
                <button class="btn form-control next py-2">
                  Next
                  <span
                    *ngIf="showSpinner"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
